export const funds = {
  MLC: {
    fundName: "MLC",
    ceoName: "Geoff Lloyd",
    fundEmail: "No",
    fundContactForm: "https://www.mlc.com.au/personal/contact-us-investor",
  },
  "Q Super": {
    fundName: "Q Super",
    ceoName: "Michael Pennisi",
    fundEmail: "No",
    fundContactForm: "https://qsuper.qld.gov.au/contact-us/email-qsuper",
  },
  "Australian Super": {
    fundName: "Australian Super",
    ceoName: "Ian Silk",
    fundEmail: "enquiry@australiansuper.com",
    fundContactForm: "https://www.australiansuper.com/email",
  },
  AMP: {
    fundName: "AMP",
    ceoName: "Francesco De Ferrari",
    fundEmail: "askamp@amp.com.au",
    fundContactForm: "https://www.amp.com.au/wps/portal/au/enquiry",
  },
  ANZ: {
    fundName: "ANZ",
    ceoName: "Shayne Elliott",
    fundEmail: "No",
    fundContactForm:
      "https://www.anz.com.au/support/contact-us/compliments-suggestions-complaints/feedback/",
  },
  BT: {
    fundName: "BT",
    ceoName: "Kathy Vincent",
    fundEmail: "No",
    fundContactForm: "https://secure.bt.com.au/forms/complaints.asp",
  },
  CBUS: {
    fundName: "CBUS",
    ceoName: "Justin Arter",
    fundEmail: "cbusenq@cbussuper.com.au",
    fundContactForm: "https://www.cbussuper.com.au/support/complaints",
  },
  "Care Super": {
    fundName: "Care Super",
    ceoName: "Julie Lander",
    fundEmail: "No",
    fundContactForm:
      "https://www.youraccountonline.com/form/aus/CARESUPER/public-contact-us.html",
  },
  "Aware (formerly First State)": {
    fundName: "Aware (formerly First State)",
    ceoName: "Deanne Stewart",
    fundEmail: "enquiries@aware.com.au",
    fundContactForm:
      "https://firststatesuper.com.au/contact#i-need-help-with-a",
  },
  "Aon Master Trust (now SmartMonday)": {
    fundName: "Aon Master Trust (now SmartMonday)",
    ceoName: "Steven Gaffney",
    fundEmail: "enquiries@smartMonday.com.au",
    fundContactForm: "0",
  },
  "Australia Post": {
    fundName: "Australia Post",
    ceoName: "Christine Holgate",
    fundEmail: "sr@apss.com.au",
    fundContactForm: "0",
  },
  "Australian Catholic Superannuation": {
    fundName: "Australian Catholic Superannuation",
    ceoName: "Greg Cantor",
    fundEmail: "fundoffice@catholicsuper.com.au",
    fundContactForm: "0",
  },
  "AustSafe Super (merged and is now SunSuper)": {
    fundName: "AustSafe Super (merged and is now SunSuper)",
    ceoName: "Bernard Reilly",
    fundEmail: "No",
    fundContactForm: "https://www.sunsuper.com.au/contact-us/email-us",
  },
  "AV Super": {
    fundName: "AV Super",
    ceoName: "Michelle Griffiths",
    fundEmail: "avsinfo@avsuper.com.au",
    fundContactForm: "0",
  },
  "Bendigo Bank Superannuation": {
    fundName: "Bendigo Bank Superannuation",
    ceoName: "Marnie Baker (MD)",
    fundEmail: "0",
    fundContactForm: "https://www.bendigobank.com.au/contact-us/enquiry/",
  },
  "BT Superannuation": {
    fundName: "BT Superannuation",
    ceoName: "Kathy Vincent",
    fundEmail: "0",
    fundContactForm: "https://secure.bt.com.au/contact-us/contact-form.asp",
  },
  "BUSSQ Superannuation": {
    fundName: "BUSSQ Superannuation",
    ceoName: "Linda Vickers",
    fundEmail: "0",
    fundContactForm: "https://www.bussq.com.au/about/contact-us",
  },
  "CBUS Superannuation": {
    fundName: "CBUS Superannuation",
    ceoName: "Justin Arter",
    fundEmail: "cbusenq@cbussuper.com.au",
    fundContactForm: "0",
  },
  "Child Care Super": {
    fundName: "Child Care Super",
    ceoName: "Mario Pirone",
    fundEmail: "admin@guildsuperservices.com.au",
    fundContactForm: "0",
  },
  "Christian Super": {
    fundName: "Christian Super",
    ceoName: "Ross Piper",
    fundEmail: "members@christiansuper.com.au",
    fundContactForm: "0",
  },
  ClearView: {
    fundName: "ClearView",
    ceoName: "Todd Kardash",
    fundEmail: "client.wealth@clearview.com.au ",
    fundContactForm: "0",
  },
  "Club Plus Super": {
    fundName: "Club Plus Super",
    ceoName: "Stefan Strano",
    fundEmail: "member@clubplussuper.com.au",
    fundContactForm: "0",
  },
  "Colonial First State": {
    fundName: "Colonial First State",
    ceoName: "Todd Stevenson",
    fundEmail: "contactus@colonialfirststate.com.au",
    fundContactForm:
      "https://www.colonialfirststate.com.au/contact_us/submit_feedback.aspx?menutabtype=contactus",
  },
  "Commonwealth Bank": {
    fundName: "Commonwealth Bank",
    ceoName: "Scott Durbin",
    fundEmail: "oursuperfund@cba.com.au",
    fundContactForm: "0",
  },
  "Crescent Wealth": {
    fundName: "Crescent Wealth",
    ceoName: "Talal Yassine (MD)",
    fundEmail: "0",
    fundContactForm: "https://crescentwealth.com.au/contact-us/",
  },
  "Emergency Services & State": {
    fundName: "Emergency Services & State",
    ceoName: "Mark Puli",
    fundEmail: "info@esssuper.com.au",
    fundContactForm: "0",
  },
  Emplus: {
    fundName: "Emplus",
    ceoName: "Alan Hegerty",
    fundEmail: "info@emplus.com.au",
    fundContactForm: "0",
  },
  "Energy Industries Super Scheme": {
    fundName: "Energy Industries Super Scheme",
    ceoName: "Alexander Hutchison",
    fundEmail: "0",
    fundContactForm: "https://www.eisuper.com.au/contact-us/",
  },
  "Energy Super": {
    fundName: "Energy Super",
    ceoName: "Robyn Petrou",
    fundEmail: "info@energysuper.com.au",
    fundContactForm: "0",
  },
  "Equip Superannuation": {
    fundName: "Equip Superannuation",
    ceoName: "Scott Cameron",
    fundEmail: "0",
    fundContactForm: "https://www.equipsuper.com.au/contact-us",
  },
  "Equity Trustees Superannuation": {
    fundName: "Equity Trustees Superannuation",
    ceoName: "Michael O'Brien (MD)",
    fundEmail: "enquiry@eqt.com.au",
    fundContactForm: "0",
  },
  Fiducian: {
    fundName: "Fiducian",
    ceoName: "Indy Singh",
    fundEmail: "0",
    fundContactForm: "https://www.fiducian.com.au/contact-us/",
  },
  "First Super": {
    fundName: "First Super",
    ceoName: "Bill Watson",
    fundEmail: "mail@firstsuper.com.au",
    fundContactForm: "0",
  },
  "GESB - Government Employees Superannuation Board": {
    fundName: "GESB - Government Employees Superannuation Board",
    ceoName: "Ben Palmer",
    fundEmail: "memberservices@gesb.com.au",
    fundContactForm: "0",
  },
  "Grow Super": {
    fundName: "Grow Super",
    ceoName: "Mathew Keeley",
    fundEmail: "grow@growsuper.com",
    fundContactForm: "0",
  },
  "Guild Super": {
    fundName: "Guild Super",
    ceoName: "Mario Pirone",
    fundEmail: "admin@guildsuperservices.com.au\n\n ",
    fundContactForm:
      "https://secure.superfacts.com/customer/GUILD/login?client_id=ZC6GOk9ttLJQQXQVuFYQpLhUmE80W9XY&redirect_uri=https://www.youraccountonline.com/form/login.html&scope=customers:website&state=a0dWam1YU3dyRnk5cVR1dy1HVUlMRA==&code_challenge=8QRUuQ7yyARxbvqAi61WzCU_pfxZxs2gQuDxni7J22s&code_challenge_method=S256&client_type=web",
  },
  Hesta: {
    fundName: "Hesta",
    ceoName: "Debby Blakey",
    fundEmail: "hesta@hesta.com.au",
    fundContactForm: "https://www.hesta.com.au/contact",
  },
  HostPlus: {
    fundName: "HostPlus",
    ceoName: "David Elia",
    fundEmail: "info@hostplus.com.au",
    fundContactForm: "0",
  },
  ING: {
    fundName: "ING",
    ceoName: "Melanie Evans",
    fundEmail: "customer.complaints@ing.com.au",
    fundContactForm: "https://www.ing.com.au/contact-us.html",
  },
  InTrust: {
    fundName: "InTrust",
    ceoName: "Brendan O'Farrell",
    fundEmail: "info@intrust.com.au",
    fundContactForm: "0",
  },
  IOOF: {
    fundName: "IOOF",
    ceoName: "Renato Mota",
    fundEmail: "superfeedback@ioof.com.au or clientfirst@ioof.com.au",
    fundContactForm: "https://www.ioof.com.au/contact-us",
  },
  "Kinetic (now Sun Super)": {
    fundName: "Kinetic (now Sun Super)",
    ceoName: "Bernard Reilly",
    fundEmail: "No",
    fundContactForm: "https://www.sunsuper.com.au/contact-us",
  },
  "Kogan Super": {
    fundName: "Kogan Super",
    ceoName: "Ruslan Kogan",
    fundEmail: "0",
    fundContactForm:
      "https://www.youraccountonline.com/form/aus/KOGANSUPER/public-contact-us.html",
  },
  legalsuper: {
    fundName: "legalsuper",
    ceoName: "Andrew Proebstl",
    fundEmail: "mail@legalsuper.com.au",
    fundContactForm: "0",
  },
  LESF: {
    fundName: "LESF",
    ceoName: "Brett Marsh",
    fundEmail: "lesf@onevue.com.au",
    fundContactForm: "0",
  },
  LGIA: {
    fundName: "LGIA",
    ceoName: "Kate Farrar",
    fundEmail: "info@lgiasuper.com.au",
    fundContactForm: "0",
  },
  "Local Government Super": {
    fundName: "Local Government Super",
    ceoName: "Phil Stockwell",
    fundEmail: "info@lgsuper.com.au",
    fundContactForm: "https://www.lgsuper.com.au/about-us/contact-us/",
  },
  "LUCRF Super": {
    fundName: "LUCRF Super",
    ceoName: "Charlie Donnelly",
    fundEmail: "0",
    fundContactForm:
      "https://lucrfsuper.atlassian.net/servicedesk/customer/portal/1/group/1/create/10002",
  },
  MAP: {
    fundName: "MAP",
    ceoName: "Wendy Tancred",
    fundEmail: "0",
    fundContactForm: "http://www.mapfunds.com.au/page/MAP_contact/",
  },
  "Meat Industry Employees": {
    fundName: "Meat Industry Employees",
    ceoName: "Bill McRobert",
    fundEmail: "0",
    fundContactForm: "https://miesf.com.au/contact-us/email-enquiry/",
  },
  "Media Super": {
    fundName: "Media Super",
    ceoName: "Tony Griffin",
    fundEmail: "0",
    fundContactForm:
      "https://secure.superfacts.com/customer/MEDIASUPER/login?client_id=PtYDAMgUyAasSAaF3Ak80R5z2D178MUT&redirect_uri=https://www.youraccountonline.com/form/login.html&scope=customers:website&state=YzIxajlPVkNIM0FiUFJtbC1NRURJQVNVUEVS&code_challenge=Q00yYzTaYA3sMlnDt_OYu3NADX6o3dzk7nVk-bvm65c&code_challenge_method=S256&client_type=web",
  },
  "Mercer Australia": {
    fundName: "Mercer Australia",
    ceoName: "Ben Walsh ",
    fundEmail: "0",
    fundContactForm: "https://secure.superfacts.com/web/mst/home.tpz",
  },
  "Military Super": {
    fundName: "Military Super",
    ceoName: "Damian Hill (of Commonwealth Superannuation Corporation)",
    fundEmail: "Members@enq.militarysuper.gov.au",
    fundContactForm: "0",
  },
  "Mine Super": {
    fundName: "Mine Super",
    ceoName: "Harry Mitchell",
    fundEmail: "help@mine.com.au",
    fundContactForm: "0",
  },
  "MTAA Super": {
    fundName: "MTAA Super",
    ceoName: "Leeanne Turner",
    fundEmail: "contact@mtaasuper.com.au",
    fundContactForm: "0",
  },
  "MyLife MySuper": {
    fundName: "MyLife MySuper",
    ceoName: "Scott Cameron",
    fundEmail: "0",
    fundContactForm: "https://mylifemysuper.com.au/form/contact",
  },
  "Nationwide Super": {
    fundName: "Nationwide Super",
    ceoName: "Ian Morante",
    fundEmail: "enquiries@nationwidesuper.com.au\n",
    fundContactForm: "0",
  },
  "NESS Super": {
    fundName: "NESS Super",
    ceoName: "Paul Cahill\n",
    fundEmail: "nessadmin@nesssuper.com.au",
    fundContactForm: "0",
  },
  Netwealth: {
    fundName: "Netwealth",
    ceoName: "Matt Heine",
    fundEmail: "contact@netwealth.com.au",
    fundContactForm: "0",
  },
  "NGS Super": {
    fundName: "NGS Super",
    ceoName: "Laura Wright",
    fundEmail: "0",
    fundContactForm:
      "https://www.youraccountonline.com/form/aus/NGSSUPER/config3/contact-us.html",
  },
  "OnePath (now Zurich)": {
    fundName: "OnePath (now Zurich)",
    ceoName: "Tim Bailey",
    fundEmail: "0",
    fundContactForm:
      "http://www.onepath.com.au/superandinvestment/enquiry.aspx",
  },
  "Perpetual Trustees": {
    fundName: "Perpetual Trustees",
    ceoName: "Perpetual Limited/CEO\nRob Adams",
    fundEmail: "investments@perpetual.com.au",
    fundContactForm: "0",
  },
  Plum: {
    fundName: "Plum",
    ceoName: "Geoff Lloyd",
    fundEmail: "0",
    fundContactForm: "https://www.plumfs.com.au/forms/enquiry.asp",
  },
  "Prime Super": {
    fundName: "Prime Super",
    ceoName: "Lachlan Baird",
    fundEmail: "administration@primesuper.com.au",
    fundContactForm: "0",
  },
  PSS: {
    fundName: "PSS",
    ceoName: "Damian Hill",
    fundEmail: "Members@dfrdb.gov.au",
    fundContactForm: "0",
  },
  "QIEC (Now NGS Super)": {
    fundName: "QIEC (Now NGS Super)",
    ceoName: "Laura Wright",
    fundEmail: "0",
    fundContactForm:
      "https://www.youraccountonline.com/form/aus/NGSSUPER/config3/contact-us.html",
  },
  QSuper: {
    fundName: "QSuper",
    ceoName: "Michael Pennisi",
    fundEmail: "0",
    fundContactForm: "https://qsuper.qld.gov.au/contact-us/email-qsuper",
  },
  "REI Super": {
    fundName: "REI Super",
    ceoName: "Jarrod Coysh",
    fundEmail: "0",
    fundContactForm:
      "https://www.youraccountonline.com/form/aus/REISUPER/config3/contact-us.html",
  },
  Rest: {
    fundName: "Rest",
    ceoName: "Vicki Doyle",
    fundEmail: "contact@rest.com.au ",
    fundContactForm: 'suggestion: subject line "Complaint"',
  },
  "Russell Investments": {
    fundName: "Russell Investments",
    ceoName: "Len Brennan",
    fundEmail: "0",
    fundContactForm:
      "https://russellinvestments.com/au/support/super-and-retirement/login",
  },
  Smartsave: {
    fundName: "Smartsave",
    ceoName: "Stephen Blood (Executive General Manager)",
    fundEmail: "smartsave@diversa.com.au",
    fundContactForm: "https://smartsavesuper.com.au/contact-us/",
  },
  Spaceship: {
    fundName: "Spaceship",
    ceoName: "Andrew Moore",
    fundEmail: "hello@spaceship.com.au",
    fundContactForm: "0",
  },
  "Squirrel Super": {
    fundName: "Squirrel Super",
    ceoName: "Damien Linn",
    fundEmail: "save@squirrelsuper.com.au",
    fundContactForm: "https://www.squirrelsuper.com.au/contact.html",
  },
  "StatePlus (SEE AWARE SUPER)": {
    fundName: "StatePlus (SEE AWARE SUPER)",
    ceoName: "Deanne Stewart",
    fundEmail: "enquiries@aware.com.au",
    fundContactForm:
      "https://firststatesuper.com.au/contact#i-need-help-with-a",
  },
  "Statewide Super": {
    fundName: "Statewide Super",
    ceoName: "Tony D'Alessandro",
    fundEmail: "info@statewide.com.au",
    fundContactForm: "https://www.statewide.com.au/contact-us/",
  },
  Suncorp: {
    fundName: "Suncorp",
    ceoName: "Steve Johnston",
    fundEmail: "customeradvocate@suncorp.com.au.",
    fundContactForm: "0",
  },
  "Sun Super": {
    fundName: "Sun Super",
    ceoName: "Bernard Reilly",
    fundEmail: "No",
    fundContactForm: "https://www.sunsuper.com.au/contact-us/email-us",
  },
  "Super SA": {
    fundName: "Super SA",
    ceoName: "Dascia Bennett",
    fundEmail: "supersa@sa.gov.au",
    fundContactForm: "https://www.supersa.sa.gov.au/contact_us/email_super_sa",
  },
  Superestate: {
    fundName: "Superestate",
    ceoName: "Grant Brits",
    fundEmail: "hello@superestate.com.au",
    fundContactForm: "https://www.superestate.com.au/contact",
  },
  Tasplan: {
    fundName: "Tasplan",
    ceoName: "Wayne Davy",
    fundEmail: "info@tasplan.com.au",
    fundContactForm: "https://www.tasplan.com.au/contact-us/",
  },
  "Telstra Super": {
    fundName: "Telstra Super",
    ceoName: "Chris Davies",
    fundEmail: "contact@telstrasuper.com.au",
    fundContactForm: "https://www.telstrasuper.com.au/contact-us/contact-form",
  },
  TWUSuper: {
    fundName: "TWUSuper",
    ceoName: "Frank Sandy",
    fundEmail: "0",
    fundContactForm: "https://www.twusuper.com.au/contact-us/",
  },
  UniSuper: {
    fundName: "UniSuper",
    ceoName: "Kevin O'Sullivan",
    fundEmail: "enquiry@unisuper.com.au",
    fundContactForm: "0",
  },
  "Vic Super": {
    fundName: "Vic Super",
    ceoName: "Michael Dundon",
    fundEmail: "memberservices@vicsuper.com.au",
    fundContactForm: "https://www.vicsuper.com.au/contact-us",
  },
  "Virgin Money": {
    fundName: "Virgin Money",
    ceoName: "Greg Boyle",
    fundEmail: "virginsuper@mercer.com",
    fundContactForm: "1300 652 770",
  },
  "Vision Super": {
    fundName: "Vision Super",
    ceoName: "Stephen Rowe",
    fundEmail: "memberservices@visionsuper.com.au",
    fundContactForm: "https://www.visionsuper.com.au/about-us/contact-us",
  },
  "WA Super (will become Aware super Dec 4)": {
    fundName: "WA Super (will become Aware super Dec 4)",
    ceoName: "Fabian Ross",
    fundEmail: "MERGING with Aware super on Dec 4th ",
    fundContactForm: "https://www.wasuper.com.au/contact-us",
  },
  Zuper: {
    fundName: "Zuper",
    ceoName: "Jessica Ellerm",
    fundEmail: "0",
    fundContactForm: "https://zuper.com.au/contact",
  },
  Zurich: {
    fundName: "Zurich",
    ceoName: "Tim Bailey",
    fundEmail: "client.service@zurich.com.au",
    fundContactForm: "0",
  },
}
